import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { LayoutPDP, LayoutPDPSection } from '../components/LayoutPDP';
import PdpFooter from '../components/Pdp/PdpFooter';
import Button from '@cof/graffiti-alley-spray-cans/molecules/Button';
import PanelDisplay from '@cof/graffiti-alley-spray-cans/molecules/PanelDisplay';
import PdpLegalDisclaimer from '@cof/graffiti-alley-spray-cans/molecules/PdpLegalDisclaimer';
import CardTile from '@cof/graffiti-alley-spray-cans/atoms/CardTile';
import SEO from '../components/SEO/SEO';
import { useWindowWidthSize } from '../hooks/useWindowWidthSize';

import storyPic1 from '../assets/images/belief-story-jason.png';
import storyPic2 from '../assets/images/belief-story-sharee.png';
import beliefHeroDesktop from '../assets/images/belief-hero-desktop.png';
import beliefHeroTablet from '../assets/images/belief-hero-tablet.png';
import beliefHeroMobile from '../assets/images/belief-hero-mobile.png';
import checkNowCardImageDesktop from '../assets/images/belief-check-now-desktop.png';
import checkNowCardImageTablet from '../assets/images/belief-check-now-tablet.png';
import checkNowCardImageMobile from '../assets/images/belief-check-now-mobile.png';
import creditScoreCardImageDesktop from '../assets/images/belief-credit-score-desktop.png';
import creditScoreCardImageTablet from '../assets/images/belief-credit-score-tablet.png';
import creditScoreCardImageMobile from '../assets/images/belief-credit-score-mobile.png';

import './belief.scss';

const enElements = (
  <>
    <div>
      <FormattedMessage id="pages.belief.legal-disclaimer.note1" values={{ sup1: <sup>1</sup> }} />
    </div>
    <div>
      <FormattedMessage id="pages.belief.legal-disclaimer.note2" values={{ sup2: <sup>2</sup> }} />
    </div>
    <div>
      <FormattedMessage id="pages.belief.legal-disclaimer.note3" />
    </div>
  </>
);

const Belief = injectIntl(({ intl }) => {
  const windowWidth = useWindowWidthSize();
  const largeScreenBreakpoint = 1024;
  const beliefPdpFooter = () => {
    return <PdpFooter intl={intl} hidePromo={true} />;
  };

  return (
    <LayoutPDP containerClass="belief-landing-page-container" CustomFooter={beliefPdpFooter}>
      <LayoutPDPSection className="hero-banner-layout-wrapper">
        <div className="belief-header">
          <div className="belief-header-content">
            <h1 data-testid="header-title">
              <FormattedMessage
                id={'pages.belief.hero-banner.title'}
                values={{
                  whiteText: (
                    <span className="hero-banner-white-text">
                      {intl.formatMessage({ id: 'pages.belief.hero-banner.values.whiteText' })}
                    </span>
                  ),
                }}
              />
            </h1>
            {windowWidth >= largeScreenBreakpoint && (
              <>
                <p className="desktop-view-subtext">
                  <FormattedMessage id={'pages.belief.hero-banner.subtitleDesktopP1'} />
                </p>
                <p>
                  <FormattedMessage id={'pages.belief.hero-banner.subtitleDesktopP2'} />
                </p>
              </>
            )}
            {windowWidth < largeScreenBreakpoint && (
              <p>
                <FormattedMessage id={'pages.belief.hero-banner.subtitle'} />
              </p>
            )}
          </div>
          <div className="belief-banner-image-box">
            <img src={beliefHeroDesktop} alt="" className="belief-banner-desktop" />
            <img src={beliefHeroTablet} alt="" className="belief-banner-tablet" />
            <img src={beliefHeroMobile} alt="" className="belief-banner-mobile" />
          </div>
        </div>
      </LayoutPDPSection>

      <LayoutPDPSection className="page-content-layout-wrapper">
        <div className="story-section">
          <h2>
            <FormattedMessage id={'pages.belief.true-stories.title'} />
          </h2>
          <h4>
            <FormattedMessage id={'pages.belief.true-stories.subtitle'} />
          </h4>
          <PanelDisplay mediaType="image" mediaSrc={storyPic1} placeHolderSrc={storyPic1} leftPanelSize={'50%'}>
            <div className="panel-content">
              <h3 className="person-story-text">
                <FormattedMessage id={'pages.belief.true-stories.story1.person'} />
              </h3>
              <h3>
                <FormattedMessage id={'pages.belief.true-stories.story1.title'} />
              </h3>
              <h5>
                <FormattedMessage id={'pages.belief.true-stories.story1.content'} />
              </h5>
              <Button
                className="read-more-button"
                color="regressive"
                hollow={true}
                url={intl.formatMessage({ id: 'pages.belief.true-stories.story1.storyLink' })}
                data-testid="read-more-button-one"
              >
                <FormattedMessage id={'pages.belief.true-stories.read-more-button'} />
              </Button>
            </div>
          </PanelDisplay>
          <PanelDisplay
            mediaType="image"
            mediaSrc={storyPic2}
            placeHolderSrc={storyPic2}
            leftPanelSize={'50%'}
            mediaOrientation={'right'}
            className="panel-display-story-2"
          >
            <div className="panel-content">
              <h3 className="person-story-text">
                <FormattedMessage id={'pages.belief.true-stories.story2.person'} />
              </h3>
              <h3>
                <FormattedMessage id={'pages.belief.true-stories.story2.title'} />
              </h3>
              <h5>
                <FormattedMessage id={'pages.belief.true-stories.story2.content'} />
              </h5>
              <Button
                className="read-more-button"
                color="regressive"
                hollow={true}
                url={intl.formatMessage({ id: 'pages.belief.true-stories.story2.storyLink' })}
                data-testid="read-more-button-two"
              >
                <FormattedMessage id={'pages.belief.true-stories.read-more-button'} />
              </Button>
            </div>
          </PanelDisplay>
        </div>

        <div className="card-tile-section">
          <h2>
            <FormattedMessage id={'pages.belief.got-you-covered.title'} />
          </h2>
          <h4>
            <FormattedMessage id={'pages.belief.got-you-covered.subtitle'} />
          </h4>
          <div className="card-tile-first-child" data-testid="yellow-card-div">
            <CardTile containerClass="browse-cards">
              <div className="browse-card-contents-box">
                <h3>
                  <FormattedMessage id={'pages.belief.got-you-covered.browse-cards.heading'} />
                </h3>
                <h5>
                  <FormattedMessage id={'pages.belief.got-you-covered.browse-cards.subheading'} />
                </h5>
                <Button
                  hollow={true}
                  className="browse-card-button"
                  url="/credit-cards/compare/?filter=all"
                  data-testid="browse-card-button-test"
                >
                  <h4>
                    <FormattedMessage id={'pages.belief.got-you-covered.browse-cards.button'} />
                  </h4>
                </Button>
              </div>
            </CardTile>
          </div>
          <div className="card-tile-second-child">
            <CardTile containerClass="left-box">
              <div className="contents-box">
                <h3>
                  <FormattedMessage id={'pages.belief.got-you-covered.quick-check.heading'} />
                </h3>
                <h5>
                  <FormattedMessage
                    id="pages.belief.got-you-covered.quick-check.subheading"
                    values={{ sup1: <sup>1</sup> }}
                  />
                </h5>
                <Button url="/quickcheck" data-testid="quick-check-button-test">
                  <h4>
                    <FormattedMessage id={'pages.belief.got-you-covered.quick-check.button'} />
                  </h4>
                </Button>
              </div>
              <div className="image-box">
                <img
                  src={checkNowCardImageDesktop}
                  alt="Mobile app showing three Capital One Mastercards."
                  className="image-desktop"
                  data-testid="check-now-desktop"
                />
                <img
                  src={checkNowCardImageTablet}
                  alt="Mobile app showing three Capital One Mastercards."
                  className="image-tablet"
                  data-testid="check-now-tablet"
                />
                <img
                  src={checkNowCardImageMobile}
                  alt="Mobile app showing three Capital One Mastercards."
                  className="image-mobile"
                  data-testid="check-now-mobile"
                />
              </div>
            </CardTile>
            <CardTile containerClass="right-box">
              <div className="contents-box">
                <h3>
                  <FormattedMessage id={'pages.belief.got-you-covered.credit-score.heading'} />
                </h3>
                <h5>
                  <FormattedMessage
                    id="pages.belief.got-you-covered.credit-score.subheading"
                    values={{ sup2: <sup>2</sup> }}
                  />
                </h5>
                <Button
                  url={intl.formatMessage({ id: 'pages.pdp.content.credit-keeper.link' })}
                  data-testid="credit-score-button-test"
                >
                  <h4>
                    <FormattedMessage id={'pages.belief.got-you-covered.credit-score.button'} />
                  </h4>
                </Button>
              </div>
              <div className="image-box">
                <img
                  src={creditScoreCardImageDesktop}
                  alt="Mobile app showing a credit score in Credit Keeper."
                  className="image-desktop"
                  data-testid="credit-score-desktop"
                />
                <img
                  src={creditScoreCardImageTablet}
                  alt="Mobile app showing a credit score in Credit Keeper."
                  className="image-tablet"
                  data-testid="credit-score-tablet"
                />
                <img
                  src={creditScoreCardImageMobile}
                  alt="Mobile app showing a credit score in Credit Keeper."
                  className="image-mobile"
                  data-testid="credit-score-mobile"
                />
              </div>
            </CardTile>
          </div>
        </div>
      </LayoutPDPSection>

      <LayoutPDPSection>
        <PdpLegalDisclaimer className="pdp-legal-disclaimer" legalTextElements={enElements} />
      </LayoutPDPSection>
    </LayoutPDP>
  );
});

export default Belief;

/* eslint-disable react/prop-types */
export const Head = ({ pageContext }) => (
  <SEO titleKey="pages.belief.seo.page-title" descriptionKey="pages.belief.seo.page-desc" intl={pageContext.intl} />
);
/* eslint-enable react/prop-types */
